<template>
  <Panel header="Zuordnung des Alarmausgangs" class="mt-3">
      <template #icons>
          <i class="fi fi-rr-bell bg-bespin fg-lightYellow p-panel-header-icon"></i>
      </template>
      <div class="formgrid grid mb-3">
          <div class="field-radiobutton col justify-content-center">
              <RadioButton id="setAlarm0" name="setMode" :value="0" v-model="this.newOutput" />
              <label for="setAlarm0" class="mr-2">AL0</label>
              <RadioButton id="setAlarm1" name="setMode" :value="1" v-model="this.newOutput" />
              <label for="setAlarm1" class="mr-2">AL1</label>
              <RadioButton id="setAlarm2" name="setMode" :value="2" v-model="this.newOutput" />
              <label for="setAlarm2" class="mr-2">AL2</label>
              <RadioButton id="setAlarm3" name="setMode" :value="3" v-model="this.newOutput" />
              <label for="setAlarm3" class="mr-2">AL3</label>
              <RadioButton id="setAlarm4" name="setMode" :value="4" v-model="this.newOutput" />
              <label for="setAlarm4" class="mr-2">AL4</label>
              <RadioButton id="setAlarm5" name="setMode" :value="5" v-model="this.newOutput" />
              <label for="setAlarm5">AL5</label>
          </div>
      </div>
  </Panel>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "widgetAlarmOutput",
  setup() {},
  components: {
  },
  emits: ['setOutput'],
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    colorVal: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      newOutput: null,
    }
  },
  watch: {
    newOutput: function () {
      this.$emit('setOutput', this.newOutput);
    },
  },
  computed: {
    widgetLabel() {
      if (this.label !== undefined) return this.label;
      return false;
    },
    iconSymbol() {
      if (this.icon !== undefined) return this.icon;
      else return '';
    },
    colorVal_1() {
      if (this.colorVal !== undefined) return this.colorVal;
      else return `fg-lime`;
    },
    colorTextLabel() {
      return `fg-gray`;
    },
  },
  mounted() {
    this.newOutput = this.value.value;
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
</style>